export const Personas = {
    "AIAR": "Systems Architect",
    "APPM": "Application Team Member",
    "BAOS": "Business Application Owner/ Sponsor",
    "DSTM": "Digital Security Team Member",
    "DTAE": "Account Executive",
    "DTCS": "Customer Success Manager",
    "DTEX": "Executive",
    "DTLP": "License Purchaser",
    "DTSA": "Service Associate",
    "DTSE": "Sales Engineer",
    "DTSM": "Service Manager",
    "DTSP": "Support Associate",
    "EOTM": "Enterprise Observability Team Member",
    "OPTM": "Operations Team Member",
    "OTHR": "Alternate Persona",
    "PLTM": "Platform Team Member",
    "PORT": "Application Portfolio Manager",
    "PROJ": "Project Manager",
    "QAPF": "QA/Performance Team Member",
};
export type Persona =  "AIAR" | "APPM" | "BAOS" | "DSTM" | "DTAE" | "DTCS" | "DTEX" | "DTLP" | "DTSA" | "DTSE" | "DTSM" | "DTSP" | "EOTM" | "OPTM" | "OTHR" | "PLTM" | "PORT" | "PROJ" | "QAPF";
export const PersonaValues: Persona[] = Object.keys(Personas) as Persona[];
